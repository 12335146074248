import { APP_LAST_UPDATED, MEMBER_LOAD_ACCOUNT_SUMMARY, MEMBER_LOAD_CAMPAIGNS_AND_BOARDS, MEMBER_LOAD_DONATIONS_AND_BOOKMARKS, MEMBER_LOAD_NOTIFICATIONS, MEMBER_CREATE_EDIT_CAMPAIGN, MEMBER_SUPPORT_LOAD, MEMBER_SUPPORT_LOAD_TICKET, MEMBER_UPDATE_PAYOUT_METHODS } from '../types'
import { chkExpiredData } from '../app/actions'

// [START]: Account Setup :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
	// ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
	export function updateMemberInfo(userInfo) {
		return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {    
			const updateProfile = firebase.functions().httpsCallable('updateProfile')
			updateProfile({ userInfo:userInfo })
			.then(() => {
			  resolve('success')
			})
			.catch((e) => {
	      	let error = {title:'Error',message:''}
	      	try { error = JSON.parse(e.message) } 
	      	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
	      	reject(error)
	      }) 
		})
	}
	export function updateMemberPassword(oldPassword, newPassword) {
		return (dispatch, getstate, firebase) => new Promise(async function (resolve, reject) {
			// Reauthenticate user with current password.
			if (oldPassword !== '') {
				try {
					const userEmail = getstate().auth.userInfo.email
					const credentials = firebase.auth.EmailAuthProvider.credential(userEmail,oldPassword)
					await firebase.auth().currentUser.reauthenticateWithCredential(credentials)
				}
				catch (error) {
					if (error.message === 'The password is invalid or the user does not have a password.') {
						return reject('The Current Password you entered is invalid, please try again or login through your email.')
					} else {
						return reject('Error authenticating your account, please re-enter your current password or login through your email then set your password.')    
					}
				}
			} 
			// Set new password
			if(newPassword){
				firebase.auth().currentUser.updatePassword(newPassword)
				.then(function() {
					resolve('Password updated')
				})
				.catch(function(error) {
					if (error.code === 'auth/requires-recent-login') {
						reject('Please enter your Current Password.')    
					} else {
						reject('Sorry, there was an error: ', error.message)  
					}
				})
			} else {
				reject('Please enter your password.')
			}        
		})
	}

	export function createGroup(groupInfo) {
		return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {
			const createGroup = firebase.functions().httpsCallable('createGroup')
			createGroup({ 
				name: groupInfo.name, 
				summary: groupInfo.summary, 
				type: groupInfo.type, 
				headerImage: groupInfo.headerImage, 
				profileImage: groupInfo.profileImage
			})
			.then((returned) => {
				// groupID is returned
				resolve(returned.data)
			})
			.catch((e) => {
	      	let error = {title:'Error',message:''}
	      	try { error = JSON.parse(e.message) } 
	      	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
	      	reject(error)
	      }) 
		})
	}
// ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
// [END]: Account Setup :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::


// [START]: SECTION - My Account ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
// ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
	export function loadAccountSummary(force=false) {
		return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {    
		   const chkIfExpired = dispatch(chkExpiredData(force, 'memberAccountSummary'))
	     	chkIfExpired
	     	.then((currentTime) => {
	     	// ---------------------------------------------------------------------------    
		      const getAccount = firebase.functions().httpsCallable('fetchAccountSummary')
		      getAccount()
		      .then((returned) => {
		         let payload = {
						timestamp: currentTime,
						credits: returned.data.credits,
						payIns: returned.data.payIns,
						payOuts: returned.data.payOuts,
						payOutMethods: returned.data.payOutMethods,
						payOutPendingRequests: returned.data.payOutPendingRequests,
						transactions: returned.data.transactions
					}
					dispatch({type: MEMBER_LOAD_ACCOUNT_SUMMARY, payload: payload})
					dispatch({ type: APP_LAST_UPDATED, payload:{key:'memberAccountSummary', value:currentTime} })
					resolve()
		      })
		      .catch((e) => {
		      	let error = {title:'Error',message:''}
		      	try { error = JSON.parse(e.message) } 
		      	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
		      	reject(error)
		      }) 
	     	// ---------------------------------------------------------------------------
	     	})
	     	.catch(() => {
	         // Current data still good so do nothing.
	         resolve()
	     	})
		})
	}
	export function getPayOutMethods(location) {
		return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {    
			const fetchMethods = firebase.functions().httpsCallable('fetchPayoutMethods')
			fetchMethods({ location:location })
			.then((returned) => {
				// payoutMethods returned
				resolve(returned.data)
			})
			.catch((e) => {
	      	let error = {title:'Error',message:''}
	      	try { error = JSON.parse(e.message) } 
	      	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
	      	reject(error)
	      }) 
		})
	}
	export function addPayOutMethod(rec) {
		return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {    
			const setMethod = firebase.functions().httpsCallable('setPayoutMethod')
			setMethod({ rec:rec })
			.then((returned) => {
				// payoutMethodId and payoutMethod info is returned
				let methods = getstate().member.payOutMethods
				methods[returned.data.id] = returned.data.info
				let payload = {
					timestamp: Math.floor(Date.now() / 1000),
					methods: methods
				}
				dispatch({type: MEMBER_UPDATE_PAYOUT_METHODS, payload: payload})				
				resolve()
			})
			.catch((e) => {
	      	let error = {title:'Error',message:''}
	      	try { error = JSON.parse(e.message) } 
	      	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
	      	reject(error)
	      })
		})
	}
	export function deletePayOutMethod(id) {
		return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {    
			const deleteMethod = firebase.functions().httpsCallable('deletePayoutMethod')
			deleteMethod({ id:id })
			.then((returned) => {
				// payoutMethodId and payoutMethod info is returned
				let methods = getstate().member.payOutMethods
				delete methods[id]
				let payload = {
					timestamp: Math.floor(Date.now() / 1000),
					methods: methods
				}
				dispatch({type: MEMBER_UPDATE_PAYOUT_METHODS, payload: payload})				
				resolve()
			})
			.catch((e) => {
	      	let error = {title:'Error',message:''}
	      	try { error = JSON.parse(e.message) } 
	      	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
	      	reject(error)
	      })
		})
	}
	export function requestPayout() {
		return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {    
			console.log('requestPayout')
			// payoutRequests
			let error = {title:'Error',message:'aaaaaaaaaaa'}
			//reject(error)
			resolve()
		})
	}
	export function cancelPayoutRequest(id) {
		return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {    
			console.log('Cancel Payout Request: ',id)
			resolve()
		})
	}
	export function loadMoreTransactions() {
		return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {    
			console.log('loadMoreTransactions')

		})
	}	
// ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
// [END]: SECTION - My Account ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::


// [START]: SECTION - Campaigns /////////////////////////////////////////////////////////////////
// //////////////////////////////////////////////////////////////////////////////////////////////
	export function fetchMemberCampaignsAndBoards(force=false) {
		return (dispatch, getstate, firebase) => new Promise(async function (resolve, reject) {
		   const chkIfExpired = dispatch(chkExpiredData(force, 'memberCampaignsAndBoards'))
	     	chkIfExpired
	     	.then((currentTime) => {
	     	// ---------------------------------------------------------------------------    
		      const getCampaignsAndBoards = firebase.functions().httpsCallable('fetchCampaignsAndBoards')
		      getCampaignsAndBoards()
		      .then((returned) => {
		         let payload = {
						timestamp: currentTime,
						campaigns: returned.data.campaigns,
						boards: returned.data.boards
					}
					dispatch({type: MEMBER_LOAD_CAMPAIGNS_AND_BOARDS, payload: payload})
					dispatch({ type: APP_LAST_UPDATED, payload:{key:'memberCampaignsAndBoards', value:currentTime} })
					resolve()
		      })
		      .catch((e) => {
		      	let error = {title:'Error',message:''}
		      	try { error = JSON.parse(e.message) } 
		      	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
		      	reject(error)
		      })  
	     	// ---------------------------------------------------------------------------
	     	})
	     	.catch(() => {
	         // Current data still good so do nothing.
	         resolve()
	     	})
		})
	}

	export function fetchCampaignInfo(id) {
		return (dispatch, getstate, firebase) => new Promise(async function (resolve, reject) {
	      const fetchInfo = firebase.functions().httpsCallable('fetchFundraiserInfo')
	      fetchInfo({ campaignId:id })
	      .then((returned) => {
	      	// returned campaign info
				resolve(returned.data)
	      })
	      .catch((e) => {
	      	let error = {title:'Error',message:''}
	      	try { error = JSON.parse(e.message) } 
	      	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
	      	reject(error)
	      })  
		})
	}

	export function addEditCampaign(cInfo) {
		return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {
			const create = firebase.functions().httpsCallable('createEditFundraiser')
	      create({ fundraiserInfo:cInfo })
	      .then((returned) => {
	      	let id = returned.data.id
	      	let info = returned.data.info
	         let payload = {
					timestamp: Math.floor(Date.now() / 1000),
					id: id,
					info: info
				}
				dispatch({type: MEMBER_CREATE_EDIT_CAMPAIGN, payload: payload})
				resolve(returned.data.id)
	      })
	      .catch((e) => {
	      	let error = {title:'Error',message:''}
	      	try { error = JSON.parse(e.message) } 
	      	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
	      	reject(error)
	      })  
		})
	}
	
	export function fetchDonorDetails(id) {
		return (dispatch, getstate, firebase) => new Promise(async function (resolve, reject) {
         const fetchDetails = firebase.functions().httpsCallable('fetchDonorDetails')
	      fetchDetails({ campaignId:id })
	      .then((returned) => {
	      	// returned campaign info
				resolve(returned.data)
	      })
	      .catch((e) => {
	      	let error = {title:'Error',message:''}
	      	try { error = JSON.parse(e.message) } 
	      	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
	      	reject(error)
	      })   
		})
	}

	export function fetchBoardDetails(id) {
		return (dispatch, getstate, firebase) => new Promise(async function (resolve, reject) {
         const fetchDetails = firebase.functions().httpsCallable('fetchBoardDetails')
	      fetchDetails({ boardId:id })
	      .then((returned) => {
	      	// returned board details {object}
				resolve(returned.data)
	      })
	      .catch((e) => {
	      	let error = {title:'Error',message:''}
	      	try { error = JSON.parse(e.message) } 
	      	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
	      	reject(error)
	      })   
			
		})
	}

	export function deleteCampaign(id) {
		return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {
			console.log('deleteCampaign')
		})
	}

	export function campaignLiveToggle(id,status) {
		return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {
			let cInfo = getstate().member.campaigns[id]
			cInfo.live = status
			let payload = {
				timestamp: Math.floor(Date.now() / 1000),
				id: id,
				info: cInfo
			}
			dispatch({type: MEMBER_CREATE_EDIT_CAMPAIGN, payload: payload})
			
			// :::::::::::::::::::::::::::::::::::::::::

			const setLive = firebase.functions().httpsCallable('setCampaignLive')
	      setLive({ campaignId:id, status:status })
	      .then((returned) => {
	      	resolve()
	      })
	      .catch((err) => {
	      	resolve()
	      })
		})
	}
// //////////////////////////////////////////////////////////////////////////////////////////////
// [END]: SECTION - Campaigns ///////////////////////////////////////////////////////////////////


// [START]: SECTION - Donations -------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------
	export function fetchMemberDonationsAndBookmarks(force=false) {
		return (dispatch, getstate, firebase) => new Promise(async function (resolve, reject) {
		   const chkIfExpired = dispatch(chkExpiredData(force, 'memberDonationsAndBookmarks'))
	     	chkIfExpired
	     	.then((currentTime) => {
	     	// ---------------------------------------------------------------------------    
				const loadDonationsBookmarks = firebase.functions().httpsCallable('fetchDonationsAndBookmarks')
		      loadDonationsBookmarks()
		      .then((returned) => {
		         let payload = {
						timestamp: currentTime,
						donations: returned.data.donations,
						bookmarks: returned.data.bookmarks
					}
					dispatch({type: MEMBER_LOAD_DONATIONS_AND_BOOKMARKS, payload: payload})
					dispatch({ type: APP_LAST_UPDATED, payload:{key:'memberDonationsAndBookmarks', value:currentTime} })
					resolve()
		      })
		      .catch((e) => {
		      	let error = {title:'Error',message:''}
		      	try { error = JSON.parse(e.message) } 
		      	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
		      	reject(error)
		      }) 
	     	// ---------------------------------------------------------------------------
	     	})
	     	.catch(() => {
	         // Current data still good so do nothing.
	         resolve()
	     	})	
		})
	}
// ---------------------------------------------------------------------------------------------------	
// [END]: SECTION - Donations ------------------------------------------------------------------------
 

// [START]: SECTION - Notifications :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
// ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
	export function loadMemberNotifications(force=false) {
		return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {    
		   const chkIfExpired = dispatch(chkExpiredData(force, 'memberNotifications'))
	     	chkIfExpired
	     	.then((currentTime) => {
	     	// ---------------------------------------------------------------------------    
		      const getNotifications = firebase.functions().httpsCallable('fetchNotifications')
		      getNotifications()
		      .then((returned) => {
		         let payload = {
						timestamp: currentTime,
						notifications: returned.data
					}
					dispatch({type: MEMBER_LOAD_NOTIFICATIONS, payload: payload})
					dispatch({ type: APP_LAST_UPDATED, payload:{key:'memberNotifications', value:currentTime} })
					resolve()
		      })
		      .catch((e) => {
		      	let error = {title:'Error',message:''}
		      	try { error = JSON.parse(e.message) } 
		      	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
		      	reject(error)
		      }) 
	     	// ---------------------------------------------------------------------------
	     	})
	     	.catch(() => {
	         // Current data still good so do nothing.
	         resolve()
	     	})
		})
	}

	export function loadMoreMemberNotifications(start='start') {
		return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {    
	      const getNotifications = firebase.functions().httpsCallable('fetchNotifications')
	      getNotifications({ startAt:start })
	      .then((returned) => {
	         let payload = {
					timestamp:  Math.floor(Date.now() / 1000),
					notifications: returned.data
				}
				dispatch({type: MEMBER_LOAD_NOTIFICATIONS, payload: payload})
				resolve()
	      })
	      .catch((e) => {
	      	resolve()
	      }) 	     	
		})
	}	
// ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
// [END]: SECTION - Notifications :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::


// [START]: SECTION - Support Tickets >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
	export function loadMemberSupport(force=false) {
		return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {    
		   const chkIfExpired = dispatch(chkExpiredData(force, 'memberSupport'))
	     	chkIfExpired
	     	.then((currentTime) => {
	     	// ---------------------------------------------------------------------------    		
				const loadSupport = firebase.functions().httpsCallable('userLoadSupport')
		      loadSupport()
		      .then((returned) => {
					let payload = {
						timestamp:  currentTime,
						supportGroups: returned.data.supportGroups,
						supportCategories: returned.data.supportCategories,
						supportTickets: returned.data.supportTickets
					}
					dispatch({type: MEMBER_SUPPORT_LOAD, payload: payload})
					dispatch({ type: APP_LAST_UPDATED, payload:{key:'memberSupport', value:currentTime} })
		         resolve()
		      })
		      .catch((e) => {
		      	let error = {title:'Error',message:''}
		      	try { error = JSON.parse(e.message) } 
		      	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
		      	reject(error)
		      })
		   // ---------------------------------------------------------------------------
	     	})
	     	.catch(() => {
	         // Current data still good so do nothing.
	         resolve()
	     	})    
		})
	}

	export function openTicket(ticket) {
		return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {
			const ticketOpen = firebase.functions().httpsCallable('userOpenTicket')
	      ticketOpen({ groupId:ticket.groupId, category:ticket.category, subject:ticket.subject, post:ticket.post})
	      .then((returned) => {
	         let payload = {
					id: returned.data.id,
					info: returned.data.info
				}
				dispatch({type: MEMBER_SUPPORT_LOAD_TICKET, payload: payload})
	      	resolve()
	      })
	      .catch((e) => {
	      	let error = {title:'Error',message:''}
	      	try { error = JSON.parse(e.message) } 
	      	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
	      	reject(error)
	      })
		})
	}

	export function updateTicket(ticketId, post) {
		return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {
			const ticketUpdate = firebase.functions().httpsCallable('userUpdateTicket')
	      ticketUpdate({ ticketId:ticketId, post:post })
	      .then((returned) => {
	      	let ticket = getstate().member.supportTickets[ticketId]
	      	ticket.thread[returned.data.recId] = returned.data.recInfo
	         let payload = {
					id: ticketId,
					info: ticket
				}
				dispatch({type: MEMBER_SUPPORT_LOAD_TICKET, payload: payload})
	      	resolve()
	      })
	      .catch((e) => {
	      	let error = {title:'Error',message:''}
	      	try { error = JSON.parse(e.message) } 
	      	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
	      	reject(error)
	      })
		})
	}

	export function closeTicket(ticketId) {
		return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {
			const ticketClose = firebase.functions().httpsCallable('userCloseTicket')
	      ticketClose({ ticketId:ticketId })
	      .then((returned) => {
	      	let ticket = getstate().member.supportTickets[ticketId]
	      	ticket.status = 'closed'
	      	ticket.closed = true
	         let payload = {
					id: ticketId,
					info: ticket
				}
				dispatch({type: MEMBER_SUPPORT_LOAD_TICKET, payload: payload})
	      	resolve()
	      })
	      .catch((e) => {
	      	let error = {title:'Error',message:''}
	      	try { error = JSON.parse(e.message) } 
	      	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
	      	reject(error)
	      })
		})
	}
// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
// [END]: SECTION - Support Tickets >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>