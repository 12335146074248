import React from 'react'
import moment from 'moment'
import Swal from 'sweetalert2'
import Loader from '../../../components/Loader'
import {money} from '../../../../includes/functions'

import Button from '@material-ui/core/Button'
import Collapse from '@material-ui/core/Collapse'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Select from '@material-ui/core/Select'
import Slider from '@material-ui/core/Slider'
import Text from '@material-ui/core/Typography'

function PayoutRequests({credits, payOutMethods, pendingRequests, requestPayout, cancelPayoutRequest}){
	const mounted = React.useRef(true)
	const [loading, setLoading] = React.useState(false)
	const [showRequestPayout, setShowRequestPayout] = React.useState(false)
	const [selectedMethod, setSelectedMethod] = React.useState(0)
	const [message, setMessage] = React.useState('')
	const [withdrawlAmount, setWithdrawlAmount] = React.useState(50)

	// [START]: Track if component is mounted
   React.useEffect(() => {
      return () => {
         mounted.current = false
      }
   },[])
   // [END]: Track if component is mounted

	const handleChangeMethod = (v) => {
		setSelectedMethod(v)
		setMessage('')
	}
	const handleChangeWithdrawl = (event, newValue) => {
    	setWithdrawlAmount(newValue)
  	}
  	const handleWithdrawlRequest = () => {
  		if (!selectedMethod) {
  			return setMessage('Please select a withdrawl method.')
  		}
		Swal.fire({
			title: 'Withdrawl Request',
			text: 'You are about to request a payout of ' + money(withdrawlAmount,0) + ' to your ' + payOutMethods[selectedMethod].methodName + ' account.',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Proceed'
		}).then((result) => {
			if (result.value) {
				setLoading(true)
				requestPayout()
				.then(() => {
					if (mounted.current) {
						setLoading(false)
						setShowRequestPayout(false)
					}
				})
				.catch((e) => {
					if (mounted.current) {
						setLoading(false)
						setMessage('Please Try Again.')
						Swal.fire({
							title: e.title,
							text: e.message,
							confirmButtonColor: '#3085d6',
							confirmButtonText: 'Ok'
						})
					}
				})
			}
		})  		
  	}
  	const handleCancelRequest = (id) => {
		Swal.fire({
			title: 'Cancel Request',
			text: 'Are you sure you would like to cancel this withdrawl request?',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes Please!'
		}).then((result) => {
			if (result.value) {
				setLoading(true)
				cancelPayoutRequest(id)
				.then(() => {
					if (mounted.current) {
						setLoading(false)
					}
				})
				.catch((e) => {
					if (mounted.current) {
						setLoading(false)
						Swal.fire({
							title: e.title,
							text: e.message,
							confirmButtonColor: '#3085d6',
							confirmButtonText: 'Ok'
						})
					}
				})
			}
		}) 
  	}

  	let pendingRequestsss = [{
  		transId: 23222,
  		dateSubmitted: 3223232323,
  		status: 'pending',
  		methodId: 2,
  		methodName: 'First Citizens Bank',
  		firstName: 'James',
  		lastName: 'Caesar',
  		branchNumber: '43433',
  		accountNumber: '3232322',
  		email: 'james.@asdf.com',
  		amount: 89
  	}]

	return (
		<div style={{marginTop:30}}>
			<Loader loading={loading} />
			<Text variant='h5' color='textSecondary'>Payout Requests</Text>
			{(!pendingRequestsss || !pendingRequestsss.length) ? (
				<div align='center' style={{margin:0, padding:50, backgroundColor:'#f1f1f1'}}><Text variant='body1' color='textSecondary'>No Pending Payout Requests Listed</Text></div>
			) : (
				<Paper>
					<List>
					{pendingRequestsss.map((request,key) => {
						return (
							<ListItem key={key}>
								<Grid container>
					     			<Grid item xs={12} sm={4}>
					     				<Text variant='caption' color='textSecondary'>{moment.unix(request.dateSubmitted).format('MMM Do YYYY - h:mm a')}</Text>
					     				<Text variant='body1'><b>{request.status}</b></Text>
					     			</Grid>
					     			<Grid item xs={12} sm={5}>
					     				<Text variant='h6' color='primary'>{request.methodName}</Text>
					     				<Text variant='body1'><b>{request.firstName + ' ' + request.lastName}</b></Text>
					     				<Text variant='body2' color='textSecondary'>{request.email}</Text>
					     				{(request.branchNumber) ? (
					     					<Text component='span' variant='body2' color='textSecondary'>{request.branchNumber + ' - '}</Text>
					     				):( null )}
					     				<Text component='span' variant='body2' color='textSecondary'>{request.accountNumber}</Text>
					     			</Grid>
					     			<Grid item xs={12} sm={3} style={{textAlign:'right'}}>
					     				<div align='right' style={{marginTop:20}}>
											<Text component='span' variant='h4' style={{color:'#d4d4d4'}}>$</Text>
											<Text component='span' variant='h4' color='primary'>{request.amount}</Text>
											<Text component='span' variant='h6' style={{color:'#d4d4d4', marginRight:5}}> USD</Text>
										</div>
					     				<Button onClick={() => { handleCancelRequest(request.transId) }} size='small'>Cancel Request</Button>
					     			</Grid>
					     			<Grid item xs={12}><Divider style={{margin:5}}/></Grid>
								</Grid>										
							</ListItem>
						)
					})}
					</List>
				</Paper>
			)}

			{(payOutMethods && Object.keys(payOutMethods).length) ? (
			<React.Fragment>	
				<div align='right'><Button size='small' color='secondary' onClick={() => {setShowRequestPayout(!showRequestPayout)}}>Request a Payout</Button></div>
				<Collapse in={showRequestPayout} timeout='auto' unmountOnExit>
					<div align='center' style={{backgroundColor:'#fafafa', padding:10, borderRadius:3}}>
					<div style={{maxWidth:400, padding:10, margin:'0px auto'}}>

						<FormControl style={{width:'100%', marginTop:15}}>
				        	<Select value={selectedMethod} onChange={(e)=>{handleChangeMethod(e.target.value)}}>
				        		<MenuItem value={0}>Select Withdrawl Method</MenuItem>
				        		{ Object.keys(payOutMethods).map((i) => {
								   return (
									 	<MenuItem key={i} value={i}>{payOutMethods[i].methodName}</MenuItem>
								   )
								})}
				        	</Select>
				      </FormControl>

				      <div align='center' style={{marginTop:20}}>
							<Text component='span' variant='h2' style={{color:'#d4d4d4'}}>$</Text>
							<Text component='span' variant='h2' color='primary'>{withdrawlAmount}</Text>
							<Text component='span' variant='h5' style={{color:'#d4d4d4'}}> USD</Text>
						</div>
						<Slider 
							value={withdrawlAmount} 
							onChange={handleChangeWithdrawl} 
							step={5}
     						min={20}
     						max={credits}
     					/>
     					<div align='center' style={{margin:10}}><Text variant='body1' style={{ color: 'red' }}><b>{message}</b></Text></div>
     					<Button onClick={handleWithdrawlRequest} variant='contained' color='secondary' size='small'>Process Withdrawl Request</Button>
					</div>
					</div>
				</Collapse>
			</React.Fragment>
			) : ( null )}
		</div>
	)
}
export default PayoutRequests
