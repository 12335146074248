import React from 'react'
import { Route, Redirect, Switch, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { logOut, changeOption, monitorLoginStatus, stopMonitoringLoginStatus } from '../redux/auth/actions'

import SplashScreen from './components/SplashScreen'
import TopNav from './components/TopNav'
import SideNav from './components/SideNav'
import Footer from './components/Footer'
//import BottomNav from './components/BottomNav'

import GroupRoutes from './GroupRoutes'
import MemberRoutes from './MemberRoutes'
import PublicRoutes from './PublicRoutes'

class App extends React.Component {
	state = { 
		loading: true,
		sideNavOpen: false, 
		publicRoute: true
	}
	sideNavToggle = () => {
		this.setState({sideNavOpen: !this.state.sideNavOpen})
	}	
	componentDidMount = () => {
		this.setState({loading:false})
		this.props.monitorLoginStatus()
	}
	componentWillUnmount = () => {
		this.props.stopMonitoringLoginStatus()
	}
	componentDidUpdate = (prevProps) => {
		if (this.props !== prevProps) {
			let publicRoute = true
			if(this.props.location.pathname.startsWith('/group') || this.props.location.pathname.startsWith('/member')) {
	         publicRoute = false
	      }
	      this.setState({publicRoute:publicRoute})
			if(publicRoute && this.props.loginOptionID) {
				this.props.changeOption(0)
			}
			if(this.props.location.pathname.startsWith('/group') && !this.props.loginOptionID) {
				this.props.history.push({ pathname: '/member' })	
			}      
		}
	}
	handleOptionChange = (option) => {
		this.props.changeOption(option)
		let page = (option > 0) ? '/group' : '/member'
		this.props.history.push({ pathname: page })
	}
	render(){
		window.scrollTo(0, 0)
		const {loading, sideNavOpen, publicRoute} = this.state
		const {version, loggedIn, loginOptions, selectedOption, logOut} = this.props

		// if data not loaded return null
		if (!selectedOption) { return null }
		
		// data loaded so show site
		return (
			<React.Fragment>
				<TopNav 
					publicRoute={publicRoute}
					loggedIn={loggedIn} 
					logOut={logOut} 
					loginOptions={loginOptions}
					selectedOption={selectedOption} 
					sideNavToggle={this.sideNavToggle}
					handleOptionChange={this.handleOptionChange}
				/>
				<nav>
					<SideNav 
						publicRoute={publicRoute}
						selectedOption={selectedOption} 
						sideNavOpen={sideNavOpen} 
						sideNavToggle={this.sideNavToggle} 
					/>
				</nav>
				<main>
					<Switch>
						<Route path='/group' render={(props) => loggedIn === true
							? <GroupRoutes {...props} />
							: <Redirect to={'/login?rURL=' + props.location.pathname} />}  // props.location.pathname = /foo/bar
						/>
						<Route path='/member' render={(props) => loggedIn === true
							? <MemberRoutes {...props} />
							: <Redirect to={'/login?rURL=' + props.location.pathname} />} // props.location.pathname = /foo/bar
						/>
						<Route path='/' render={(props) => <PublicRoutes {...props}
								loggedIn={loggedIn}
							/>}	
						/>
					</Switch>
				</main>
				{ /* <Footer publicRoute={publicRoute}/> */}
				{ /* (!publicRoute) ? ( <BottomNav selectedOption={ selectedOption } /> ) : ( null ) */}
				{(!loading && version) ? ( <SplashScreen /> ) : ( null )}
			</React.Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	version: state.app.version,
	loggedIn: state.auth.loggedIn,
	loginOptions: state.auth.loginOptions,
	loginOptionID: state.auth.loginOptionID,
	selectedOption: state.auth.loginOptions[state.auth.loginOptionID]
})
const mapDispatchToProps = {
	logOut,
	changeOption,
	monitorLoginStatus,
	stopMonitoringLoginStatus
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App))
