import React from 'react'
import { Link as RouterLink, withRouter } from 'react-router-dom'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Text from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'

const Footer = (props) => {
	if(props.publicRoute) {
		return (
			<div style={{backgroundColor:'gray', textAlign:'center'}}>
			<Container maxWidth='lg' style={{ padding:10, paddingBottom:50 }}>
				<Grid container justify='center' spacing={2}>
					<Grid item xs={12} sm={4} md={3}>
						<div style={{textAlign:'left'}} >
							<Text variant='h6' style={{color:'white'}}>About</Text>
							<Link component={RouterLink} to='/aboutus' variant='subtitle1' style={{color:'lightGray'}}>About Us</Link><br/>
							<Link component={RouterLink} to='/termsofservice' variant='subtitle1' style={{color:'lightGray'}}>Terms of Service</Link><br/>
							<Link component={RouterLink} to='/privacy' variant='subtitle1' style={{color:'lightGray'}}>Privacy Policy</Link><br/>
							<Link component={RouterLink} to='/contactus' variant='subtitle1' style={{color:'lightGray'}}>Contact Us</Link><br/>
						</div>
					</Grid>
					<Grid item xs={12} sm={4} md={3} >
						<div style={{textAlign:'left'}} >
							<Text variant='h6' style={{color:'white'}}>Learn More</Text>
							<Link component={RouterLink} to='/howitworks' variant='subtitle1' style={{color:'lightGray'}}>How It Works</Link><br/>
							<Link component={RouterLink} to='/faqs' variant='subtitle1' style={{color:'lightGray'}}>Common Questions</Link><br/>
							<Link component={RouterLink} to='/fees' variant='subtitle1' style={{color:'lightGray'}}>Pricing & Fees</Link><br/>
							<Link component={RouterLink} to='/press' variant='subtitle1' style={{color:'lightGray'}}>Press Releases</Link><br/>
						</div>
					</Grid>
					<Grid item xs={12} sm={4} md={3} >
						<div style={{textAlign:'left'}} >
							<Text variant='h6' style={{color:'white'}}>Resources</Text>
							<Link component={RouterLink} to='/blog' variant='subtitle1' style={{color:'lightGray'}}>Blog</Link><br/>
							<Link component={RouterLink} to='/tips' variant='subtitle1' style={{color:'lightGray'}}>Fundraising Tips</Link><br/>
							<Link component={RouterLink} to='/affilates' variant='subtitle1' style={{color:'lightGray'}}>Affilates</Link><br/>
							<Link component={RouterLink} to='/help' variant='subtitle1' style={{color:'lightGray'}}>Help Center</Link><br/>
						</div>
					</Grid>
					<Grid item xs={12} sm={12} md={3} >
						<div style={{textAlign:'left'}} >
							<Text variant='h6' style={{color:'white'}}>LetsFundIt</Text>
							<Text variant='subtitle1' style={{color:'lightGray'}}>
								letsFundIt is a CrowdFunding website for regular people that want to get stuff done.
							</Text>
						</div>
					</Grid>
				</Grid>
			</Container>
			</div>
		)
	} else {
		return (
			<div style={{backgroundColor:'gray', textAlign:'center'}}>private footer</div>
		)
	}
}
export default withRouter(Footer)
