import React from 'react'
import { withRouter } from 'react-router-dom'
import Payments from '../../components/Payments/'
import {money, formattedNumber} from '../../../includes/functions'

import Button from '@material-ui/core/Button'
import Text from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

function Step5 ({loggedIn, boardId, boardName, boardOwner, boardFee, donationRequirement, campaignId}) {
	let total = boardFee + donationRequirement
	
	if (!loggedIn) return null
	return (
		<div>
			<div style={{ margin:'0px auto', padding:20, maxWidth:600 }}>
				<Text variant='body1'>
				This <b>{boardName}</b> Feature Board is owned, marketed and managed by <b>{boardOwner}</b>. 
				There is a minimal fee of <b>{money(boardFee,0)}</b> to process your submission and list your campaign. Please review our 
 				to view all other fees and guidlines.
 				</Text>
			</div>
			<div align='center' style={{ margin:'0px auto', maxWidth:600 }}>
				<Grid container justify='space-around' spacing={0}>
					<Grid item xs={12} sm={6} style={{textAlign:'center'}}>
						<Text component='span' variant='body1'>Donation:</Text>
						<Text component='span' variant='h6'> {money(donationRequirement,0)}</Text>
					</Grid>
					<Grid item xs={12} sm={6} style={{textAlign:'center'}}>
						<Text component='span' variant='body1' style={{marginLeft:40}}>Registration Fee:</Text>
						<Text component='span' variant='h6'> {money(boardFee,0)}</Text>
					</Grid>
				</Grid>
			</div>

			<div style={{textAlign:'center', maxWidth:600, margin:'10px auto'}}>
				<Text variant='button' color='secondary'>Make Payment</Text>
				<div align='center'>
					<Text component='span' variant='h2' style={{color:'#d4d4d4'}}>$</Text>
					<Text component='span' variant='h2' color='secondary'>{total}</Text>
					<Text component='span' variant='h5' style={{color:'#d4d4d4'}}> USD</Text>
				</div>
				<div align='center' style={{backgroundColor:'#f1f1f1', padding:10, borderRadius:5, paddingBottom:20}}>
					<Text variant='h6' color='secondary'>Select Payment Method</Text>
					<Payments 
						type='stepbystep' 
						id={boardId}
						amount={total}
						message={campaignId}
						checkedAnonymous=''
						checkedHideMessage=''
						paymentDescription='Donation + Registration'
						buttonText='make payment'
					/>
				</div>
			</div>
			
		</div>
	)
}
export default withRouter(Step5)
