import React from 'react'
import moment from 'moment'
import swal from 'sweetalert2'
import ImageSelector from '../../components/ImageSelector'
import Loader from '../../components/Loader'

import { green } from '@material-ui/core/colors'
import Button from '@material-ui/core/Button'
import Text from '@material-ui/core/Typography'
import Collapse from '@material-ui/core/Collapse'
import IconExpandLess from '@material-ui/icons/ExpandLess'
import IconExpandMore from '@material-ui/icons/ExpandMore'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'

function Step4 ({loggedIn, boardId, memberCampaigns, addEditCampaign, handleSetCampaignId}) {
	const mounted = React.useRef(true)
	const [loading, setLoading] = React.useState(false)
	const [showNew, setShowNew] = React.useState(false)
	const [message, setMessage] = React.useState('')
	const [campaignId, setCampaignId] = React.useState('0')
	const [campaigns, setCampaigns] = React.useState(memberCampaigns)
	const [promoImage, setPromoImage] = React.useState('/images/campaigns/default.jpg')
	const [promoImages, setPromoImages] = React.useState(['/images/campaigns/default.jpg'])
	const [category, setCategory] = React.useState(0)
	const [title, setTitle] = React.useState('')
	const [about, setAbout] = React.useState('')

	// [START]: Track if component is mounted
   React.useEffect(() => {
	   if (!campaigns.length) {
			setShowNew(true)
		}
      return () => {
         mounted.current = false
      }
   },[campaigns])
   // [END]: Track if component is mounted



	const handleSelectPromo = (src) => {
		let x = promoImages
		if (!x.includes(src)) {
			x.push(src)
		}
		setPromoImages(x)
		setPromoImage(src)
		setMessage('')
	}
	const handleChange = (f,value) => {
		f(value)
		setMessage('')
	}

	const handleCreateCampaign = () => {
		if (!category) {
			return setMessage('Sorry, please select a Category for your campaign')
		}
		if (title === '' || about === '') {
			return setMessage('Sorry, both Title and About are required')
		}		
		swal.fire({
		  	imageUrl: promoImage,
		  	html: '<div align="left"><b>' + title+ '</b><br/>' + about + 
		  	'<hr/><i style="color:gray">Category: ' + category + '</i><br /><i style="color:gray"> Target: $5000 USD </i></div>',
		  	showCancelButton: true,
		  	confirmButtonText: 'Save & Publish',
		  	confirmButtonColor: '#32a852',
		}).then((result) => {
		  	if (result.isConfirmed) {
		  		setShowNew(false)
		  		setMessage('')
		  		let info = {
					id: null,
					ownerType: 'member',
					type: 'fundraiser',
					listed: true,			
					category: category,
					target: 5000,
					stopAtTarget: false,
					donationType: 'any',
					donationFixedAmount: 100,
					deadline: false,
					dateStart: moment(Date.now()).format('X'), // current date in seconds
					dateEnd: moment(Date.now()).add(180, 'd').format('X'), // current date plus 180 days in seconds
					title: title,
					about: about,
					headerImage: '/images/campaigns/default.jpg', //null,
					promoImage: promoImage, //null,
					thankyouMessage: 'Thankyou for supporting me and contributing to my campaign. Your kindness is greatly appreciated.',
					thankyouEmail: 'Thankyou for supporting me and contributing to my campaign. Your kindness is greatly appreciated.',
					live: true,
					featured: false,	
				}
				setLoading(true)
				addEditCampaign(info)
		  		.then((id) => {
		  			
		  			let campaign = {id:id, title:title}
		  			let c = campaigns
		  			c.push(campaign)
		  			setCampaigns(c)
		  			setCampaignId(id)
		  			setPromoImage('/images/campaigns/default.jpg')
		  			setCategory(0)
		  			setTitle('')
		  			setAbout('')
		  			setLoading(false)	    	
		  		})
		  		.catch((e) => {
		  			console.log(e)
					setMessage(e)
		  		})
		  	} 
		})
	}
	const toggleShowNew = () => {
		setShowNew(!showNew)
	}
	const handleSelectCampaign = (value) => {
   	setMessage('')
   	setCampaignId(value)
   }
	const handleNext = () => {
		if (campaignId === '0') {
			return setMessage('Please Select a Campaign to Feature.')
		}
		handleSetCampaignId(campaignId)
	}

	if (!loggedIn) return null
	return (
		<div>
			<Loader loading={loading} />
    		<div style={{ margin:'0px auto', marginBottom:25, padding:20, maxWidth:600 }}>
 				<Text variant='body1'>
 					Submit the campaign you would like to feature on this board. When your spot in the queue is reached, your campaign would be automatically featured.
				</Text> 
				{(campaigns.length) ? (
	        		<div>
		        		<div style={{textAlign:'center', borderTop: '2px solid pink', borderBottom: '2px solid pink', margin: '10px auto', padding:10}}>
		        			<Text variant='button' color='secondary'>select campaign</Text>
		        			<TextField select fullWidth onChange={(e) => { handleSelectCampaign(e.target.value) }} value={campaignId} size='small' variant='outlined' id='category'>
							  	<MenuItem value='0' >Campaigns</MenuItem>
							  	{campaigns.map((c) => {
							  		return <MenuItem key={c.id} value={c.id}>{c.title}</MenuItem>
							  	})}
							</TextField>
		        			<Text variant='caption' color='textSecondary'><i>Please Note: This Board's settings will over-ride any conflicting Campaign settings. (eg: Target, Exp Date, etc)</i></Text>
		        		</div>
	        		</div>
        		) : ( null )}

				<div align='right'>
					{(showNew) ? (
						<Button onClick={toggleShowNew} size='small' color='secondary' startIcon={<IconExpandLess />}>Create New Campaign</Button>
					): (
						<Button onClick={toggleShowNew} size='small' color='secondary' startIcon={<IconExpandMore />}>Create New Campaign</Button>
					)}
				</div>

				<Collapse in={showNew} timeout='auto' unmountOnExit>
						<div style={{marginTop:20}}>
							<Text variant='subtitle2' color='primary'>Select or Upload an Image for your campaign.</Text>
							{/** ImageSelector Starts Here */}
							<div align='center' style={{ maxWidth: 600, margin:'0 auto', marginTop:0}}>
								<ImageSelector 
									imgBorder='1px solid #ccc'
									imgBorderRadius={10}
									imgPadding={5}
									imgRatio='16:9'
									images={promoImages}
									currentImage={promoImage}
									selectImage={handleSelectPromo}
								/>
							</div>
							{/** ImageSelector Ends Here */}
						</div>
						<div style={{marginTop:20}}>	
							<Text variant='subtitle2' color='primary'>Select a category for your fundraiser?</Text>
							<TextField select fullWidth onChange={(e) => { handleChange(setCategory, e.target.value) }} value={category} size='small' variant='outlined' id='category'>
							  	<MenuItem value='0' >Select Category</MenuItem>
							  	<MenuItem value='Charity'>Charity</MenuItem>
							  	<MenuItem value='Education'>Education</MenuItem>
							  	<MenuItem value='Family'>Family</MenuItem>
							  	<MenuItem value='Home Improvement'>Home Improvement</MenuItem>
							  	<MenuItem value='Repairs'>Repairs</MenuItem>
							</TextField>
						</div>
						<div style={{marginTop:20}}>
							<Text variant='subtitle2' color='primary'>Give your campaign a title</Text>
							<TextField 
								placeholder='Campaign Title'
								fullWidth 
								onChange={(e) => { handleChange(setTitle, e.target.value) }} 
								value={title} 
								size='small' 
								variant='outlined' 
							/>
						</div>
						<div style={{marginTop:20}}>
							<Text variant='subtitle2' color='primary'>Tell us about this campaign</Text>
							<TextField 
								placeholder='About Campaign'
								multiline
			          		rows={4}
								fullWidth 
								onChange={(e) => { handleChange(setAbout, e.target.value) }} 
								value={about} 
								size='small' 
								variant='outlined'
							/>
						</div>

						<div align='center' style={{padding:10, marginTop:20}}>
							<Button onClick={handleCreateCampaign} variant='contained' color='secondary'>Create Campaign</Button>
						</div>


				</Collapse>
			     		
    		
    		</div>
			<div align='center' style={{ minHeight: 50, padding: 10, borderBottom: '2px solid #ddd' }}>
            <Text variant='h6' style={{ color: 'red' }}>{message}</Text>
         </div>
			<div align='right' style={{marginTop:20}}>
			 	<Button variant='contained' style={{backgroundColor:green[500], color:'#fff'}} onClick={handleNext}>
					Submit Campaign
			 	</Button>
			</div>
		</div>
	)
}
export default Step4
