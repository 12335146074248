import React from 'react'
import { withRouter } from 'react-router-dom'
import countryList from '../../../../includes/countryList'

import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Text from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'

function AddMethod({history, userInfo, getPayOutMethods, addPayOutMethod}){
	const mounted = React.useRef(true)
	const [loading, setLoading] = React.useState(true)
	const [loadingError, setLoadingError] = React.useState(false)
	const [message, setMessage] = React.useState('')
	const [paymentMethods, setPaymentMethods] = React.useState(undefined)	
	const [selectedMethod, setSelectedMethod] = React.useState(0)

	// ..................
	const [firstName, setFirstName] = React.useState(userInfo.firstName || '')
	const [lastName, setLastName] = React.useState(userInfo.lastName || '')
	const [branchNumber, setBranchNumber] = React.useState('')
	const [accountNumber, setAccountNumber] = React.useState('')
	const [email, setEmail] = React.useState(userInfo.email || '')
	// ..................

	const country = countryList.find(o => o.code === userInfo.location).name

	// [START]: Track if component is mounted
   React.useEffect(() => {
	   getPayOutMethods(userInfo.location)
	   .then((methods) => {
	   	if (mounted.current) {
	   		setLoading(false)
	   		setPaymentMethods(methods)
	   	}
	   })
	   .catch((e) => {
	   	if (mounted.current) {
	   		setLoading(false)
	   		setLoadingError(true)
	   	}
	   })
      return () => {
         mounted.current = false
      }
   },[getPayOutMethods, userInfo.location])
   // [END]: Track if component is mounted
	
	const handleChange = (f,v) => {
		f(v)
		setMessage('')
	}
   const goTo = (path) => {
		history.push({ pathname: path })
	}
	const handleChangeMethod = (v) => {
		setSelectedMethod(v)
		setMessage('')
		setBranchNumber('')
		setAccountNumber('')
	}
	const handleAddPayout = () => {
		let rec = {
			type: selectedMethod.type,
			id: selectedMethod.id,
			name: selectedMethod.name,
		}
		if (firstName) { rec.firstName = firstName }
		if (lastName) { rec.lastName = lastName }
		if (branchNumber) { rec.branchNumber = branchNumber }
		if (accountNumber) { rec.accountNumber = accountNumber }
		if (email) { rec.email = email } 

		if (!firstName || !lastName) {
			return setMessage('Please enter your first and last name as it appairs on selected account.')
		}
		if (selectedMethod.branchNumber && !branchNumber) {
			return setMessage('Please enter the branch number for selected account.')
		}
		if (selectedMethod.accountNumber && !accountNumber) {
			return setMessage('Please enter the account number for selected account.')
		}
		if (selectedMethod.email && !email) {
			return setMessage('Please enter the email address used for selected account.')
		}
		setLoading(true)
		addPayOutMethod(rec)
		.then(() => {
			if (mounted.current) {
				setLoading(false)
				setMessage('Payment Method Added')
				setSelectedMethod(0)
			}
		})
		.catch((e) => {
			if (mounted.current) {
				setLoading(false)
				setMessage(e.message)
			}
		})
	}

	if (loading) {
		return (
			<div>
	         <CircularProgress color='secondary' style={{ margin: '5px auto'}} />
	         <Text variant='h6'>loading...</Text>
	      </div>
		)
	}
	if (loadingError) { 
		return (
			<div align='center' style={{margin:'20px auto'}}>
				<Text variant='h6' color='textSecondary'>Server Error</Text>
				<Text variant='body1' color='textSecondary'>Sorry, there was an error loading payment methods, please try again.</Text>
			</div>
		) 
	}

	return (
		<div style={{maxWidth:400, padding:10, margin:'0px auto'}}>
			{(!paymentMethods || !paymentMethods.length) ? (
				<div align='center' style={{margin:'20px auto'}}>
					<Text variant='h6' color='textSecondary'>No Payout Methods</Text>
					<Text variant='body1'>Sorry, there are currently no payout methods listed for your current location.</Text>
				</div>
			) : (
				<div>
					
					<FormControl style={{width:'100%', marginTop:15}}>
			        	<Select value={selectedMethod} onChange={(e)=>{handleChangeMethod(e.target.value)}}>
			        		<MenuItem value={0}>Select New Payout Method</MenuItem>
			        		{paymentMethods.map((m) => {
							   return (
								 	<MenuItem key={m.id} value={m}>{m.type + ' - '+ m.name}</MenuItem>
							   )
							})}
			        	</Select>
			      </FormControl>

			      <div style={{marginTop:15, padding:5, textAlign:'left'}}>
				      {(selectedMethod.name) ? (
				      	<Text variant='h6' color='secondary'>{selectedMethod.name}</Text>
				      ) : ( null)}

				      {(selectedMethod.firstName) ? (
				      	<TextField fullWidth
				      		style={{marginTop:0}}
								label='First Name (required)'
								value={firstName}
								onChange={(e) => {handleChange(setFirstName, e.target.value) }}
								margin='normal'
							/>
				      ) : ( null)}

				      {(selectedMethod.lastName) ? (
				      	<TextField fullWidth
				      		style={{marginTop:0}}
								label='Last Name (required)'
								value={lastName}
								onChange={(e) => {handleChange(setLastName, e.target.value) }}
								margin='normal'
							/>
				      ) : ( null)}
	
						{(selectedMethod.branchNumber) ? (
				      	<TextField fullWidth
				      		style={{marginTop:0}}
								label='Branch Number'
								value={branchNumber}
								onChange={(e) => {handleChange(setBranchNumber, e.target.value) }}
								margin='normal'
							/>
				      ) : ( null)}

				      {(selectedMethod.accountNumber) ? (
				      	<TextField fullWidth
				      		style={{marginTop:0}}
								label='Account Number'
								value={accountNumber}
								onChange={(e) => {handleChange(setAccountNumber, e.target.value) }}
								margin='normal'
							/>
				      ) : ( null)}

 						{(selectedMethod.email) ? (
				      	<TextField fullWidth
				      		style={{marginTop:0}}
								label='Email Address'
								value={email}
								onChange={(e) => {handleChange(setEmail, e.target.value) }}
								margin='normal'
							/>
				      ) : ( null)}

 						{(selectedMethod) ? (
	 						<div align='center' style={{ padding:5 }}>
								<div style={{margin:'5px auto'}}>
									<Button onClick={handleAddPayout} fullWidth variant='outlined' color='secondary'>Add Payout Method</Button>
								</div>
							</div>
						) : ( null)}	
			      </div>

				</div>
			)}
			<Text variant='body1' style={{ color: 'red' }}><b>{message}</b></Text>
			<Divider style={{margin:5, marginTop:10}} />
			<Text variant='body1' color='textSecondary'>Payout Methods are based on your Location. Your current location is set to {country}.</Text>
			<Button onClick={() => { goTo('/member/profile') }} size='small' style={{padding:0, color:'gray'}}>Click Here to update it.</Button>
		</div> 
	)
}
export default withRouter(AddMethod)
