export const APP_LAST_UPDATED = 'app:lastUpdated'
export const APP_LOAD_SETTINGS = 'app:loadSettings'

export const AUTH_CHANGE_LOGIN_OPTION = 'auth:changeLoginOption'
export const AUTH_LOAD_LOGIN_OPTIONS = 'auth:loadLoginOptions'
export const AUTH_LOAD_USER_CREDITS = 'auth:loadUserCredits'
export const AUTH_LOGIN_USER = 'auth:loginUser'
export const AUTH_LOGOUT_USER = 'auth:logoutUser'
export const AUTH_USER_INFO = 'auth:userInfo'
export const AUTH_SET_GROUPID = 'auth:setGroupId'

export const GROUP_LOAD_CAMPAIGNS = 'group:loadCampaigns'
export const GROUP_CREATE_EDIT_CAMPAIGN = 'group:createEditCampaign'
export const GROUP_LOAD_BOARDS = 'group:loadBoards'
export const GROUP_CREATE_EDIT_BOARD = 'group:createEditBoard'
export const GROUP_RESET_DATA = 'group:resetData'
export const GROUP_LOAD_SUPPORT_TICKETS = 'group:loadSupportTickets'
export const GROUP_UPDATE_SUPPORT_TICKET = 'group:updateSupportTicket'
export const GROUP_LOAD_TEAM = 'group:loadTeam'

export const MEMBER_CREATE_EDIT_CAMPAIGN = 'member:createEditCampaign'
export const MEMBER_LOAD_ACCOUNT_SUMMARY = 'member:loadAccountSummary'
export const MEMBER_LOAD_CAMPAIGNS_AND_BOARDS = 'member:loadCampaignsAndBoards'
export const MEMBER_LOAD_DONATIONS_AND_BOOKMARKS = 'member:loadDonationsAndBookmarks'
export const MEMBER_LOAD_NOTIFICATIONS = 'member:loadNotifications'
export const MEMBER_LOAD_MORE_NOTIFICATIONS = 'member:loadMoreNotifications'
export const MEMBER_SUPPORT_LOAD = 'member:supportLoad'
export const MEMBER_SUPPORT_LOAD_TICKET = 'member:supportLoadTicket'
export const MEMBER_UPDATE_PAYOUT_METHODS = 'member:supportLoadTicket'

export const PUBLIC_LOAD_BOARDS = 'public:loadBoards'
export const PUBLIC_LOAD_CAMPAIGNS = 'public:loadCampaigns'
export const PUBLIC_LOAD_CAMPAIGN_INFO = 'public:loadCampaignInfo'
export const PUBLIC_LOAD_CAMPAIGN_DONATIONS = 'public:loadCampaignDonations'
export const PUBLIC_FOLLOW_CAMPAIGN = 'public:followCampaign'
export const PUBLIC_LOAD_CHARITIES = 'public:loadCharities'
