import React from 'react'
import Swal from 'sweetalert2'
import { withRouter } from 'react-router-dom'

import {money} from '../../../includes/functions'
import Loader from '../../components/Loader'
import ViewPayOutsList from './ViewPayOutsList'
import AddMethod from './components/AddMethod'
import PayoutRequests from './components/PayoutRequests'

import Alert from '@material-ui/lab/Alert'
import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Collapse from '@material-ui/core/Collapse'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import IconClose from '@material-ui/icons/Close'
import IconPayouts from '@material-ui/icons/CallMade'
import IconExpandMore from '@material-ui/icons/ExpandMore'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Paper from '@material-ui/core/Paper'
import Slide from '@material-ui/core/Slide'
import Text from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'
import withMobileDialog from '@material-ui/core/withMobileDialog'



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />
})

function Payouts({fullScreen, history, userInfo, credits, payOutActivity, payOutMethods, getPayOutMethods, addPayOutMethod, deletePayOutMethod, pendingRequests, requestPayout, cancelPayoutRequest}) {
	const [loading, setLoading] = React.useState(false)
	const [showAlert, setShowAlert] = React.useState(true)
	const [viewPayouts, setViewPayouts] = React.useState(false)
	const [expanded, setExpanded] = React.useState(false)
	const [showRequestPayout, setShowRequestPayout] = React.useState(false)
	const [addMethod, setAddMethod] = React.useState(false)
	const payouts = payOutActivity

	const handleChange = (panel) => (event, isExpanded) => {
    	setExpanded(isExpanded ? panel : false);
  	}
  	const toggleViewAllPayouts = () => {
  		setViewPayouts(!viewPayouts)
  	}
  	const goTo = (path) => {
		history.push({ pathname: path })
	}
	const handleDeletePayoutMethod = (id) => {
		Swal.fire({
			title: 'Delete Payout Method',
			text: 'Are you sure you want to delete this payout method?',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, delete it!'
		}).then((result) => {
			if (result.value) {
				setLoading(true)
				deletePayOutMethod(id)
				.then(() => {
					setLoading(false)
				})
				.catch((error) => {
					setLoading(false)
					Swal.fire({
						title: 'Delete Payout Method',
						text: 'Sorry, there was an error deleting this payout method.',
						confirmButtonColor: '#3085d6',
						confirmButtonText: 'Ok'
					})
				})
			}
		})
	}
	return (
		<React.Fragment>
			<Paper style={{ padding: 20, minHeight:800 }} >
				<Loader loading={loading} />
				<Grid container>
	     			<Grid item xs={12} sm={6} style={{textAlign:'left'}} >
	     				<Text variant='h4' color='primary' style={{lineHeight:1}}><b>Credits</b></Text>
	     				<Text variant='overline' color='textSecondary'><b>current balance</b></Text>
	     			</Grid>
	     			<Grid item xs={12} sm={6} style={{textAlign:'right'}} >
	     				<Text variant='h4' component='span' color='primary'><b>{ money(credits,0) } </b></Text>
						<Text variant='caption' component='span' color='textSecondary'><b> USD</b></Text>
	     			</Grid>
	     			<Grid item xs={12}><Divider/></Grid>
				</Grid>

				{(!payOutMethods || !Object.keys(payOutMethods).length) ? (
						<Collapse in={showAlert}>
						<div align='center' style={{margin:'0px auto', marginTop:20, maxWidth:600}}>
							<Alert severity="error"
								action={
					            <IconButton color='inherit' size='small' onClick={() => { setShowAlert(false) }}>
					              <IconClose fontSize='inherit' />
					            </IconButton>
					          }
							>You need to setup a <b>Payout Method</b> before you can <b>Request A Payout</b>.</Alert>
						</div>
						</Collapse>
				) : (null)}
			
				<div style={{marginTop:30}}>
					<Text variant='h5' color='textSecondary'>Payout History</Text>
					<ViewPayOutsList payouts={payouts} count={5}/>
					<div align='right'><Button size='small' color='secondary' onClick={toggleViewAllPayouts}>View All</Button></div>					
					<Collapse in={showRequestPayout} timeout='auto' unmountOnExit>
						<div align='center' style={{backgroundColor:'#fafafa', padding:10, borderRadius:3}}>
							select payout method
						</div>
					</Collapse>
				</div>

				<PayoutRequests 
					credits={credits} 
					payOutMethods={payOutMethods} 
					pendingRequests={pendingRequests} 
					requestPayout={requestPayout} 
					cancelPayoutRequest={cancelPayoutRequest}
				/>

				<div style={{marginTop:30}}>
					<Text variant='h5' color='textSecondary'>Payout Methods</Text>
					{(!payOutMethods || !Object.keys(payOutMethods).length) ? (
						<div align='center' style={{margin:0, padding:50, backgroundColor:'#f1f1f1'}}><Text variant='body1' color='textSecondary'>No Payout Methods Listed</Text></div>
					) : (
						<Paper>
							<List>
							{Object.keys(payOutMethods).map((i,key) => {
								return (
									<ListItem key={key}>
										<Grid container>
							     			<Grid item xs={12} sm={3}>
							     				<Text variant='caption' color='textSecondary'>{payOutMethods[i].methodType}</Text>
							     				<Text variant='body1'><b>{payOutMethods[i].methodName}</b></Text>
							     			</Grid>
							     			<Grid item xs={12} sm={6}>
							     				<Text variant='body1' color='primary'><b>{payOutMethods[i].firstName + ' ' + payOutMethods[i].lastName}</b></Text>
							     				<Text variant='body2' color='textSecondary'>{payOutMethods[i].email}</Text>
							     				{(payOutMethods[i].branchNumber) ? (
							     					<Text component='span' variant='body2' color='textSecondary'>{payOutMethods[i].branchNumber + ' - '}</Text>
							     				):( null )}
							     				<Text component='span' variant='body2' color='textSecondary'>{payOutMethods[i].accountNumber}</Text>
							     			</Grid>
							     			<Grid item xs={12} sm={3} style={{textAlign:'right'}}>
							     				<Button onClick={() => { handleDeletePayoutMethod(i) }} size='small'>Delete This method</Button>
							     			</Grid>
							     			<Grid item xs={12}><Divider style={{margin:5}}/></Grid>
										</Grid>										
									</ListItem>
								)
							})}
							</List>
						</Paper>
					)}
					<div align='right'><Button size='small' color='secondary' onClick={() => {setAddMethod(!addMethod)}}>Add Payout Method</Button></div>
					<Collapse in={addMethod} timeout='auto' unmountOnExit>
						<div align='center' style={{backgroundColor:'#fafafa', padding:10, borderRadius:3}}>
							<AddMethod 
								userInfo={userInfo} 
								getPayOutMethods={getPayOutMethods} 
								addPayOutMethod={addPayOutMethod}
							/>
						</div>
					</Collapse>
				</div>
				<div style={{marginTop:150, marginBottom:100}}>
					<Text variant='h5' color='textSecondary'>Transfer Credits</Text>
					<Text variant='body1' color='textSecondary'>
						Transfer Funds to another LetsFundIt member via their email address. 
						This transaction CAN NOT be reversed, so please ensure you enter the correct email address. 
						<Button onClick={() => { goTo('/member/transfer') }} size='small' style={{padding:0, color:'gray'}}> Click Here to transfer credits</Button>
					</Text>
				</div>
			</Paper>


			<Dialog keepMounted fullScreen={fullScreen} open={viewPayouts} TransitionComponent={Transition} onClose={toggleViewAllPayouts}>
				<AppBar style={{ position: 'relative' }} >
               <Toolbar>
                   <IconPayouts style={{ marginRight: 5 }} />
                   <Text variant='subtitle2' color='inherit' style={{ flex: 1 }}>
                      	Payout History
                   </Text>
                   <IconButton color='inherit' onClick={toggleViewAllPayouts}>
                       <IconClose />
                   </IconButton>
               </Toolbar>
           	</AppBar>
           	<DialogContent>
           		<ViewPayOutsList payouts={payouts}/>
           	</DialogContent>
            <DialogActions>
               <Button onClick={toggleViewAllPayouts} color='primary'>Close</Button>
            </DialogActions>
			</Dialog>			
		</React.Fragment>
	)
}
export default withMobileDialog()(withRouter(Payouts))
